exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-components-card-tsx": () => import("./../../../src/pages/components/Card.tsx" /* webpackChunkName: "component---src-pages-components-card-tsx" */),
  "component---src-pages-components-footer-styled-ts": () => import("./../../../src/pages/components/footer.styled.ts" /* webpackChunkName: "component---src-pages-components-footer-styled-ts" */),
  "component---src-pages-components-footer-tsx": () => import("./../../../src/pages/components/Footer.tsx" /* webpackChunkName: "component---src-pages-components-footer-tsx" */),
  "component---src-pages-components-header-styled-ts": () => import("./../../../src/pages/components/header.styled.ts" /* webpackChunkName: "component---src-pages-components-header-styled-ts" */),
  "component---src-pages-components-header-tsx": () => import("./../../../src/pages/components/Header.tsx" /* webpackChunkName: "component---src-pages-components-header-tsx" */),
  "component---src-pages-components-layout-tsx": () => import("./../../../src/pages/components/Layout.tsx" /* webpackChunkName: "component---src-pages-components-layout-tsx" */),
  "component---src-pages-components-loading-dots-styled-ts": () => import("./../../../src/pages/components/LoadingDots.styled.ts" /* webpackChunkName: "component---src-pages-components-loading-dots-styled-ts" */),
  "component---src-pages-components-loading-dots-tsx": () => import("./../../../src/pages/components/LoadingDots.tsx" /* webpackChunkName: "component---src-pages-components-loading-dots-tsx" */),
  "component---src-pages-components-modal-styled-ts": () => import("./../../../src/pages/components/modal.styled.ts" /* webpackChunkName: "component---src-pages-components-modal-styled-ts" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-realisations-styled-ts": () => import("./../../../src/pages/realisations.styled.ts" /* webpackChunkName: "component---src-pages-realisations-styled-ts" */),
  "component---src-pages-realisations-tsx": () => import("./../../../src/pages/realisations.tsx" /* webpackChunkName: "component---src-pages-realisations-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

